






































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import SearchBar from "@/components/common/SearchBar.vue";

@Component({
  components: {
    SearchBar,
  },
})
export default class ListSearchAndSelect extends Vue {
  @Prop() readonly ListTitleLabel !: string;
  @Prop() readonly ListSelectionLabel !: string ;
  @Prop() readonly searchBarLabel !: string;

  private SearchText: string = "";
  @Watch('SearchText')
  SearchTextChanged(newVal: string){
    this.$emit('SearchText', newVal);
  }

  public SelectAll(){
      this.$emit('SelectAll', true);
  }
}
