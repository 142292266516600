<template>
    <div class="pa-5">
        <!-- text styles doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Text Styles</h3>
                <v-divider class="mb-4"></v-divider>
                <span class="title1Reg blackHigh--text">
                    Text/Left/Black/High/Title1/Regular
                </span><br>
                <span class="title1Lt blackHigh--text">
                    Text/Left/Black/High/Title1/Light
                </span><br><br>
                <span class="body1Reg blackHigh--text">
                    Text/Left/Black/High/Body1/Regular
                </span><br>
                <span class="body1Lt blackHigh--text">
                    Text/Left/Black/High/Body1/Light
                </span><br><br>
                <span class="overlineCapsReg blackHigh--text">
                    Text/Left/Black/High/Overline(All Caps)/Regular
                </span><br>
                <span class="overlineCapsLt blackHigh--text">
                    Text/Left/Black/High/Overline(All Caps)/Light
                </span><br><br>
                <span class="bodyReg blackHigh--text">
                    Text/Left/Black/High/Body/Regular
                </span><br>
                <span class="bodyLt blackHigh--text">
                    Text/Left/Black/High/Body/Light
                </span><br><br>
                <span class="suboverlineCapsReg blackHigh--text">
                    Text/Left/Black/High/Sub-overline(All Caps)/Regular
                </span><br>
                <span class="suboverlineCapsLt blackHigh--text">
                    Text/Left/Black/High/Sub-overline(All Caps)/Light
                </span><br><br>
                <span class="captionLt blackHigh--text">
                    Text/Left/Black/High/Caption/Light
                </span><br><br>
                <span class="title1Reg primaryDark--text">
                    Text/Left/FA Dark/High/Title1/Regular
                </span><br><br>
                <span class="body1Reg red--text">
                    Text/Left/Red/High/Body1/Regular
                </span><br><br>
                <span class="captionLt red--text">
                    Text/Left/Red/High/Caption/Light
                </span><br>
                <span class="captionLt primaryDark--text">
                    Text/Left/FA Blue/High/Caption/Light
                </span><br><br>
            </v-col>
        </v-row>
        <!-- icons doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Icons</h3>
                <v-divider class="mb-4"></v-divider>
                <v-icon class="blackHigh--text">
                    mdi-arrow-left
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-close
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-plus
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-refresh
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-chevron-down
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-magnify
                </v-icon>
                <v-icon class="blackHigh--text">
                    mdi-check
                </v-icon>
            </v-col>
        </v-row>
        <!-- button doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Primary Button</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="lightGreen" dark height="32" class="overlineCapsReg" depressed v-bind="attrs" v-on="on">
                            <v-icon x-small class="mr-1" style="margin-top: 2px;">mdi-circle</v-icon>
                            Button
                        </v-btn>
                    </template>
                    <small class="font-weight-thin">Tooltip</small>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Side Drawer Primary Button</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="lightGreen" dark height="32" class="overlineCapsReg" block depressed v-bind="attrs" v-on="on">
                            Button
                        </v-btn>
                    </template>
                    <small class="font-weight-thin">Tooltip</small>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Side Drawer Deactivated Button</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn disabled height="32" class="overlineCapsReg" style="background-color: #d8d8d8 !important; color: rgba(0, 0, 0, 0.6) !important;" block depressed v-bind="attrs" v-on="on">
                            Button
                        </v-btn>
                    </template>
                    <small class="font-weight-thin">Tooltip</small>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Secondary Button (Rested)</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="backgroundDark" height="32" class="overlineCapsReg blackHigh--text" style="border: 1px solid #d8d8d8; border-color: #d8d8d8 !important;" depressed v-bind="attrs" v-on="on">
                            <v-icon x-small class="mr-1" style="margin-top: 2px;">mdi-circle</v-icon>
                            Button
                        </v-btn>
                    </template>
                    <small class="font-weight-thin">Tooltip</small>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Secondary Button</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="backgroundDark" height="32" class="suboverlineCapsReg primaryFABlue--text" style="border: 1px solid #d8d8d8; border-color: #d8d8d8 !important;" depressed v-bind="attrs" v-on="on">
                            Button
                        </v-btn>
                    </template>
                    <small class="font-weight-thin">Tooltip</small>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">Tertiary Button</h3>
                <v-divider class="mb-4"></v-divider>
                <v-tooltip bottom color="blackHigh" class="mt-8">
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn color="primaryFABlue" dark depressed height="32" class="suboverlineCapsReg" small v-bind="attrs" v-on="on">
                            Button
                        </v-btn>
                    </template>
                    <small>Tooltip</small>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- Input text field / dropdown doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Basic Input Field
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-text-field placeholder="Field Name Here" :rules="inputRules">
                    <template v-slot:label>
                        <span class="red--text">* </span>Field Name Floater
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Deactivated Input Field
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-text-field placeholder="Field Name Here" label="Field Name Flowter" :rules="inputRules" disabled 
                :error-messages="`Explain why deactivated`"></v-text-field>
            </v-col>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Dropdown Input Field
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-autocomplete placeholder="Guide user on selecting" prepend-inner-icon="mdi-magnify" return-object>
                    <template v-slot:label class="primary--text captionLt">
                        <span class="red--text">* </span>Field Name Floater
                    </template>
                    <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="blackMedium--text bodyLt">
                                    No data found
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-icon slot="append">
                        mdi-chevron-down
                    </v-icon>
                </v-autocomplete>
            </v-col>
        </v-row>
        <!-- List Item doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    List Item
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-card max-width="450" class="pa-4">
                    <v-list two-line>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-title class="body1Reg blackHigh--text">
                                    List Item Entry
                                </v-list-item-title>
                                <v-list-item-subtitle class="suboverlineCapsLt blackMedium--text">
                                    Identifier Text
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-title class="body1Reg blackHigh--text">
                                    List Item Entry
                                </v-list-item-title>
                                <v-list-item-subtitle class="suboverlineCapsLt blackMedium--text">
                                    Identifier Text
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    List Item Search and Selecct
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-card max-width="850" class="pa-8">
                    <OutletFilter>

                    </OutletFilter>
                </v-card>
            </v-col>
        </v-row>
        <!-- Selection (checkbox) doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Selection
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-checkbox color="primary" label="Checkbox Field">
                    <template v-slot:label>
                        <span class="font-12 blackHigh--text">Checkbox Field</span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <!--Snackbar doc -->
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Snackbar
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-btn dark color="primary" @click="snackbar=true">
                    Open Snackbar
                </v-btn>
                <Snackbar :message="message" :snackbar.sync="snackbar" :timeout="snackbarTimeout" @closeSnackbar="closeSnackbar"></Snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Side Drawer
                </h3>
                <v-divider class="mb-4"></v-divider>
                <v-btn dark color="primary" @click="sideDrawer=true">
                    Open Side Drawer
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h3 class="font-weight-regular mb-4">
                    Navigation Bar
                </h3>
                <NavBar :navbarTitle="navbarTitle" @primaryEvent="``" :primaryBtnText="`Button`" :primaryBtnIcon="`mdi-circle`">
                    <template v-slot:secondaryBtnSlot>
                        <v-btn color="backgroundDark" height="32" class="overlineCapsReg blackHigh--text" style="border: 1px solid #d8d8d8; border-color: #d8d8d8 !important;" depressed v-bind="attrs" v-on="on">
                            <v-icon x-small class="mr-1" style="margin-top: 2px;">mdi-circle</v-icon>
                            Button
                        </v-btn>
                    </template>
                </NavBar>
            </v-col>
        </v-row>
        <SideDrawer :sidedrawerTitle="sidedrawerTitle" :sideDrawer.sync="sideDrawer" @closeDrawer="closeSideDrawer">
            <v-btn color="success" height="32" class="overlineCapsReg mx-4" block depressed style="bottom: 16px;" 
            absolute v-text="`Button`">
            </v-btn>
        </SideDrawer>
        <h3 class="font-weight-regular mb-4 mt-4">
            Chips
        </h3>
        <v-divider class="mb-4"></v-divider>
        <v-btn plain elevation="0" x-small class="overlineCapsLt blackMedium-text mx-1 small-toggle-button">
            Chip
        </v-btn>
        <v-btn-toggle
            class="ml-2 mt-1 " style="max-height: 30px;"
        >
            <v-btn plain elevation="0" x-small class="overlineCapsLt blackMedium-text mx-1 small-toggle-button">
                Toggle Chip
            </v-btn>
        </v-btn-toggle>
        <h3 class="font-weight-regular mb-4 mt-4">
            Filter Section
        </h3>
        <v-divider class="mb-4"></v-divider>
        <FilterSection header="Filter Section"/>
        <h3 class="font-weight-regular mb-4 mt-4">
            Date Range Picker
        </h3>
        <v-divider class="mb-4"></v-divider>
        <DateFilter class="pa-5 mt-3" ref="dateFilter" :type="1" :presets="['Last 7 Days', 'MTD']" :maxDays="90">
        </DateFilter>
        <h3 class="font-weight-regular mb-4">
            Checkbox
        </h3>
        <v-divider class="mb-4"></v-divider>
        <v-checkbox style="width: 300px; height:24px;" class="checkbox-small" dense
        >
            <template v-slot:label>
                <div class="bodyReg blackHigh--text">
                    check me
                </div>
            </template>
        </v-checkbox>
        <h3 class="font-weight-regular mt-3 mb-4">
            Checkbox
        </h3>
        <v-divider class="mb-4"></v-divider>
        <MultiSelect ref="gsmSelect" class="ma-3 mt-8" :items="[{name: 'Option1', id: 1},{name: 'Option3', id: 3},{name: 'Option2', id: 2}]" placeholder="Type to search" label="Select Multiple Options">
        </MultiSelect>
    </div>
</template>

<script>
    import Snackbar from './../components/common/Snackbar.vue'
    import SideDrawer from './../components/common/SideDrawer.vue';
    import OutletFilter from './Campaign/New/Helpers/Outlet/OutletFilter.vue';
    import NavBar from './common/Navbar.vue';
    import DateFilter from './common/Inputs/DateFilter.vue'
    import FilterSection from './common/Headers/FilterSection.vue'
    import MultiSelect from './common/Inputs/MultiSelect.vue';
    export default {
        data(){
            return{
                inputRules: [
                    v => !!v || 'Error Message'
                ],
                snackbar: false,
                message: 'Confirm to user event was successfull',
                snackbarTimeout: 10000,
                drawer: false,
                navbarTitle: "Page Header Name",
                sidedrawerTitle: "Side Drawer Title",
                sideDrawer: false
            }
        },
        components: {
            Snackbar,
            NavBar,
            SideDrawer,
            OutletFilter,
            DateFilter,
            FilterSection,
            MultiSelect
        },
        methods: {
            closeSideDrawer(value){
                this.sideDrawer = value;
            },
            closeSnackbar(value){
                this.snackbar = value;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>